module.exports = [{
      plugin: require('/Users/patrick/dev/euronoize.eu/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-123578853-1"},
    },{
      plugin: require('/Users/patrick/dev/euronoize.eu/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590},
    },{
      plugin: require('/Users/patrick/dev/euronoize.eu/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/patrick/dev/euronoize.eu/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
